import classNames from 'classnames'
import { FC, ReactEventHandler, ReactNode } from 'react'
import { IconContext } from 'react-icons'

interface IButton {
	children?: ReactNode
	icon?: ReactNode
	iconPlacement?: 'left' | 'right'
	type?: 'default' | 'primary' | 'text'
	onClick?: ReactEventHandler
}

const Button: FC<IButton> = ({ children, icon, iconPlacement, type, ...rest }) => {
	return (
		<button
			className={classNames('capitalize inline-flex px-4 py-2 rounded-md items-center', {
				'flex-row-reverse': iconPlacement === 'right',
				border: type !== 'text',
			})}
			{...rest}
		>
			<IconContext.Provider value={{ size: '18px' }}>
				<i className={classNames(icon && (iconPlacement === 'right' ? 'ml-2' : 'mr-2'))}>{icon}</i>
			</IconContext.Provider>
			{children}
		</button>
	)
}

export default Button
