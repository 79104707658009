import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { FaFolderOpen, FaRegFileAudio } from 'react-icons/fa'
import Button from '@/components/Button'
import Player from '@/components/Player'
import { ImMusic } from 'react-icons/im'

const Dashboard = () => {
	const directoryRef = useRef<HTMLInputElement>(null)
	const fileRef = useRef<HTMLInputElement>(null)

	const [playlist, setPlayList] = useState<File[]>([])

	useEffect(() => {
		if (directoryRef.current !== null) {
			directoryRef.current.setAttribute('directory', '')
			directoryRef.current.setAttribute('webkitdirectory', '')
			directoryRef.current.setAttribute('mozdirectory', '')
		}
	}, [playlist])

	function clearPlaylist() {
		setPlayList([])
	}

	function handleUploadFiles(event: ChangeEvent<HTMLInputElement>) {
		const files = event.target.files
		if (files) {
			setPlayList(Array.from(files).filter(({ type }) => type.includes('audio')))
		}
	}

	const openUploadFolder = () => {
		directoryRef.current?.click()
	}

	const openUploadFiles = () => {
		fileRef.current?.click()
	}

	return playlist.length === 0 ? (
		<div className='w-screen h-screen overflow-auto flex flex-col items-center justify-center gap-6'>
			<div className='p-16 rounded-full flex items-center justify-center bg-gradient-to-tr from-slate-600 to-cyan-700 shadow-2xl'>
				<div className='p-8 border-2 border-pink-300 rounded-full flex items-center justify-center bg-gradient-to-b from-indigo-500 via-purple-500 to-pink-500'>
					<div className='p-6 border-4 border-pink-300 rounded-full flex items-center justify-center bg-gradient-to-r from-cyan-500 to-blue-500'>
						<ImMusic size='32px' color='#fff' />
					</div>
				</div>
			</div>
			<div className='flex items-center gap-3'>
				<Button icon={<FaRegFileAudio />} onClick={openUploadFiles}>
					Choose files
				</Button>
				<input
					ref={fileRef}
					type='file'
					multiple
					accept='audio/*'
					onChange={handleUploadFiles}
					hidden
				/>
				<span>or</span>
				<Button icon={<FaFolderOpen />} onClick={openUploadFolder}>
					Open folder
				</Button>
				<input ref={directoryRef} type='file' onChange={handleUploadFiles} hidden />
			</div>
		</div>
	) : (
		<Player playlist={playlist} onClear={clearPlaylist} />
	)
}

export default Dashboard
