import { FC, useEffect, useState } from 'react'
import {
	FaFastBackward,
	FaFastForward,
	FaPause,
	FaPlay,
	FaRandom,
	FaVolumeDown,
	FaVolumeUp,
} from 'react-icons/fa'
import { ImLoop, ImMusic } from 'react-icons/im'
import '@/assets/styles/Player.scss'
import classNames from 'classnames'
import roundNumber from '@/utils/round-number'

interface IPlayer {
	playlist: File[]
	onClear: () => void
}

const Player: FC<IPlayer> = ({ playlist, onClear }) => {
	const [selectedIndex, setSelectedIndex] = useState(0)
	const [audio, setAudio] = useState<HTMLAudioElement>(new Audio())
	const [duration, setDuration] = useState(0)
	const [currentTime, setCurrentTime] = useState(0)
	const [isPaused, setIsPaused] = useState(false)
	const [volume, setVolume] = useState(1.0)
	const [loop, setLoop] = useState(false)
	const [random, setRandom] = useState(false)

	useEffect(() => {
		audio.pause()
		audio.currentTime = 0
		setCurrentTime(0)
		setIsPaused(false)
		setAudio(new Audio(URL.createObjectURL(playlist[selectedIndex])))
	}, [selectedIndex])
	useEffect(() => {
		audio.onloadedmetadata = () => {
			if (audio.readyState > 0) {
				setDuration(roundNumber(audio.duration, 1))
			}
		}
		audio.ontimeupdate = () => {
			setCurrentTime(roundNumber(audio.currentTime, 1))
		}
		audio.onvolumechange = () => {
			setVolume(audio.volume)
		}
		audio.autoplay = true
	}, [audio])
	useEffect(() => {
		if (loop) {
			audio.loop = true
		} else {
			audio.loop = false
			audio.onended = () => {
				if (random) {
					let randomIndex
					do {
						randomIndex = Math.floor(Math.random() * (playlist.length - 1))
					} while (randomIndex === selectedIndex)
					setSelectedIndex(randomIndex)
				} else {
					handleNext()
				}
			}
		}
	}, [loop, random, audio])

	function calculateTime(secs: number) {
		const minutes = Math.floor(secs / 60)
		const seconds = Math.floor(secs % 60)
		const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`
		return `${minutes}:${returnedSeconds}`
	}

	function handlePrev() {
		if (selectedIndex > 0) {
			setSelectedIndex(selectedIndex + -1)
		} else {
			setSelectedIndex(playlist.length - 1)
		}
	}
	function handleNext() {
		if (selectedIndex < playlist.length - 1) {
			setSelectedIndex(selectedIndex + 1)
		} else {
			setSelectedIndex(0)
		}
	}
	function handleSwitchPauseAudio() {
		if (isPaused) {
			audio.play()
		} else {
			audio.pause()
		}
		setIsPaused(!isPaused)
	}
	function handleDownVolume() {
		if (volume > 0) {
			audio.volume = volume - 0.1
		}
	}
	function handleUpVolume() {
		if (volume < 1) {
			audio.volume = volume + 0.1
		}
	}

	return (
		<div className='h-screen w-screen flex flex-col'>
			<div className='flex items-center basis-16 shrink-0 gap-4 justify-center md:gap-8 flex-wrap py-2 px-4 sticky top-0 bg-white'>
				<div className='flex gap-4 order-1'>
					<FaRandom
						onClick={() => setRandom(!random)}
						color={random ? '#000' : '#f0f0f0'}
						className='cursor-pointer'
					/>
					<FaFastBackward onClick={handlePrev} className='cursor-pointer' />
					{isPaused ? (
						<FaPlay onClick={handleSwitchPauseAudio} className='cursor-pointer' />
					) : (
						<FaPause onClick={handleSwitchPauseAudio} className='cursor-pointer' />
					)}
					<FaFastForward onClick={handleNext} className='cursor-pointer' />
					<ImLoop
						onClick={() => setLoop(!loop)}
						color={loop ? '#000' : '#f0f0f0'}
						className='cursor-pointer'
					/>
				</div>
				<div className='flex flex-1 gap-2 order-2 md:order-1'>
					<span>{calculateTime(currentTime)}</span>
					<input
						type='range'
						id='seek-slider'
						max={duration}
						step='0.1'
						value={currentTime}
						className='flex flex-1'
						onChange={(e) => {
							audio.currentTime = parseFloat(e.target.value)
						}}
					/>
					<span>{calculateTime(duration)}</span>
				</div>
				<div className='flex gap-2 order-1'>
					<FaVolumeDown onClick={handleDownVolume} className='cursor-pointer' />
					<input
						type='range'
						id='volume-slider'
						max='1.0'
						value={volume}
						step='0.1'
						onChange={(e) => {
							audio.volume = parseFloat(e.target.value)
						}}
					/>
					<FaVolumeUp onClick={handleUpVolume} className='cursor-pointer' />
				</div>
			</div>
			<div className='p-2 border flex flex-col flex-1 '>
				<div className='flex justify-between items-center px-2 mb-4'>
					<h3 className='text-2xl font-bold'>Playlist</h3>
					<button
						className='text-md text-slate-400 hover:text-slate-600'
						onClick={() => {
							audio.pause()
							onClear()
						}}
					>
						Clear
					</button>
				</div>
				<div className='flex flex-wrap cursor-pointer gap-6'>
					{playlist.map((file, i) => (
						<div
							key={i}
							className={classNames(
								'w-48 h-48 flex flex-col items-center border rounded-lg',
								i === selectedIndex && 'border-blue-300 border-2',
							)}
							onClick={() => {
								setSelectedIndex(i)
							}}
						>
							<div className='w-full flex flex-1 justify-center items-center'>
								<ImMusic color={i === selectedIndex ? '#93c5fd' : '#f0f0f0'} size='56px' />
							</div>
							<div
								className={classNames(
									'flex flex-shrink-0 h-20 p-2 text-sm break-all whitespace-pre-wrap overflow-hidden border-t',
									i === selectedIndex && 'text-blue-300 border-blue-300',
								)}
							>
								{file.name}
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	)
}

export default Player
